import React from "react";
import { Col } from "reactstrap";
import { Accordion, AccordionBlock, FeaturedProject, FeaturedProjectBlock, FilteredProjects, FilteredProjectsBlock, Header, HeaderBlock, Image, ImageBlock, OwnersBlock, OwnersProps, Paragraph, ParagraphBlock, ParagraphCallout, ParagraphCalloutBlock, ParagraphImage, ParagraphImageBlock, ParagraphImageFull, ParagraphImageFullBlock, Partners, PartnersBlock, Quote, QuoteBlock, SelectedProjects, SelectedProjectsBlock, Steps, StepsBlock, WithSidebar, WithSidebarBlock } from "components/Blocks";
import { PlaceholderHeader } from "components/Header";
import { StreamProps } from "./schema";
export const Stream: React.FC<StreamProps> = ({
  blocks,
  children,
  wrapColumn = false,
  placeholderHeader = false
}: StreamProps) => {
  if (blocks.length < 1) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <React.Fragment>
      {placeholderHeader && blocks.length > 1 && blocks[0].blockType !== "header" ? <PlaceholderHeader /> : null}
      {blocks.map((block, index) => {
      switch (block.blockType) {
        case "header":
          return <HeaderBlock {...block as Header} key={index} />;
        case "featured_project":
          return <FeaturedProjectBlock {...block as FeaturedProject} key={index} />;
        case "steps":
          return <StepsBlock {...block as Steps} key={index} />;
        case "selected_projects":
          return <SelectedProjectsBlock {...block as SelectedProjects} key={index} />;
        case "paragraph":
          return <ParagraphBlock {...block as Paragraph} key={index} />;
        case "paragraph_image":
          return <ParagraphImageBlock {...block as ParagraphImage} key={index} />;
        case "paragraph_image_full":
          return <ParagraphImageFullBlock {...block as ParagraphImageFull} key={index} />;
        case "paragraph_callout":
          return <ParagraphCalloutBlock {...block as ParagraphCallout} key={index} />;
        case "filtered_projects":
          return <FilteredProjectsBlock {...block as FilteredProjects} key={index} />;
        case "quote":
          return <QuoteBlock {...block as Quote} key={index} />;
        case "accordion":
          return <AccordionBlock {...block as Accordion} key={index} />;
        case "image":
          return <ImageBlock {...block as Image} key={index} />;
        case "with_sidebar":
          return <WithSidebarBlock {...block as WithSidebar} key={index} />;
        case "partners":
          return <PartnersBlock {...block as Partners} key={index} />;
        case "owners":
          return <OwnersBlock {...block as OwnersProps} key={index} />;
        default:
          return <React.Fragment />;
      }
    }).map((node, index) => {
      if (wrapColumn) {
        return <React.Fragment key={index}>
                <Col md={12}>{node}</Col>
              </React.Fragment>;
      }
      return node;
    })}
    </React.Fragment>;
};