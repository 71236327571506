/**
 * GraphQL schema and types for loading streampages
 *
 * When new blocks are added to a StreamField, `make generate-graphql` should be run
 * to update the the Unions that GraphQL understands.
 */
import { gql } from "@apollo/client";
import { Accordion, AccordionBlockFragment, FeaturedProject, FeaturedProjectBlockFragment, FilteredProjects, FilteredProjectsBlockFragment, Header, HeaderBlockFragment, Image, ImageBlockFragment, OwnersBlockFragment, OwnersProps, Paragraph, ParagraphBlockFragment, ParagraphCallout, ParagraphCalloutBlockFragment, ParagraphImage, ParagraphImageBlockFragment, ParagraphImageFull, ParagraphImageFullBlockFragment, Partners, PartnersBlockFragment, Quote, QuoteBlockFragment, SelectedProjectBlockFragment, SelectedProjects, Steps, StepsBlockFragment, WithSidebar, WithSidebarBlockFragment } from "../../components/Blocks";
type BodyBlock = Header | FeaturedProject | Steps | SelectedProjects | Paragraph | ParagraphImage | ParagraphImageFull | ParagraphCallout | FilteredProjects | Quote | Accordion | Image | WithSidebar | Partners | OwnersProps;
type AllBlocks = Header | FeaturedProject | Steps | SelectedProjects | Paragraph | ParagraphImage | ParagraphImageFull | ParagraphCallout | FilteredProjects | Quote | Accordion | Image | WithSidebar | Partners | OwnersProps;
interface StreamPage {
  body: BodyBlock[];
  title: string;
}
export interface PageData {
  streamPage: StreamPage;
}
export interface QueryData {
  streamPage?: StreamPage;
}
export interface QueryVariables {
  slug: string;
  draft?: boolean;
}
export interface StreamProps {
  /** Stream of blocks to display */
  blocks: AllBlocks[];
  /** Children elements to fall back too if there are no items in the stream */
  children: React.ReactNode;
  /** If the first block is not a header type use a placeholder header */
  placeholderHeader?: boolean;
  /** Wrap blocks with a column with given props */
  wrapColumn?: boolean;
}
export const PAGE_QUERY = gql`
  query streamPage($slug: String, $draft: Boolean) {
    streamPage(slug: $slug, draft: $draft) {
      title
      body {
        ... on AccordionBlock {
          ...AccordionBlockFragment
        }
        ... on FeaturedProjectBlock {
          ...FeaturedProjectBlockFragment
        }
        ... on FilteredProjectsBlock {
          ...FilteredProjectsBlockFragment
        }
        ... on HeaderBlock {
          ...HeaderBlockFragment
        }
        ... on ImageBlock {
          ...ImageBlockFragment
        }
        ... on OwnersBlock {
          ...OwnersBlockFragment
        }
        ... on ParagraphBlock {
          ...ParagraphBlockFragment
        }
        ... on ParagraphCalloutBlock {
          ...ParagraphCalloutBlockFragment
        }
        ... on ParagraphImageBlock {
          ...ParagraphImageBlockFragment
        }
        ... on ParagraphImageFullBlock {
          ...ParagraphImageFullBlockFragment
        }
        ... on PartnersBlock {
          ...PartnersBlockFragment
        }
        ... on QuoteBlock {
          ...QuoteBlockFragment
        }
        ... on SelectedProjectBlock {
          ...SelectedProjectBlockFragment
        }
        ... on StepsBlock {
          ...StepsBlockFragment
        }
        ... on WithSidebarBlock {
          ...WithSidebarBlockFragment
        }
      }
    }
  }
  ${AccordionBlockFragment}
  ${FeaturedProjectBlockFragment}
  ${FilteredProjectsBlockFragment}
  ${HeaderBlockFragment}
  ${ImageBlockFragment}
  ${OwnersBlockFragment}
  ${ParagraphBlockFragment}
  ${ParagraphCalloutBlockFragment}
  ${ParagraphImageBlockFragment}
  ${ParagraphImageFullBlockFragment}
  ${PartnersBlockFragment}
  ${QuoteBlockFragment}
  ${SelectedProjectBlockFragment}
  ${StepsBlockFragment}
  ${WithSidebarBlockFragment}
`;