"use client";

/**
 * Display stream page
 */
import { useSuspenseQuery } from "@apollo/client";
import { notFound } from "next/navigation";
import React from "react";
import { FilteredProjectsBlock, ParagraphBlock } from "components/Blocks";
import { PlaceholderHeader } from "components/Header";
import { PAGE_QUERY, PageData, QueryData, QueryVariables } from "./schema";
import { Stream } from "./stream";

/**
 * Display stream page
 *
 * @param streamPage body list of blocks to display
 */
const BasePage = ({
  streamPage: {
    body,
    title
  }
}: PageData) => {
  return <div className="stream-page" data-sentry-component="BasePage" data-sentry-source-file="StreamPage.tsx">
      {title !== "Home" ? <div>
          <title>{title}</title>
        </div> : null}

      <Stream blocks={body} placeholderHeader={true} data-sentry-element="Stream" data-sentry-source-file="StreamPage.tsx">
        <React.Fragment>
          <PlaceholderHeader data-sentry-element="PlaceholderHeader" data-sentry-source-file="StreamPage.tsx" />
          <ParagraphBlock paragraph="Catalyzing communities on climate change in the Gulf of Maine Watershed.

A curated collection of citizen science missions focused on climate change - in a place where warming is occurring faster than 99% of the world's oceans." data-sentry-element="ParagraphBlock" data-sentry-source-file="StreamPage.tsx" />
          <FilteredProjectsBlock data-sentry-element="FilteredProjectsBlock" data-sentry-source-file="StreamPage.tsx" />
        </React.Fragment>
      </Stream>
    </div>;
};
export const StreamPage: React.FC<QueryVariables> = ({
  slug
}: {
  slug: string;
}) => {
  const {
    data
  } = useSuspenseQuery<QueryData, QueryVariables>(PAGE_QUERY, {
    variables: {
      slug
    }
  });
  if (typeof data.streamPage === "undefined" || data.streamPage === null) {
    notFound();
  }
  return <BasePage streamPage={data.streamPage} data-sentry-element="BasePage" data-sentry-component="StreamPage" data-sentry-source-file="StreamPage.tsx" />;
};